import React, { Fragment, useMemo } from "react";

import { transformToCurrency } from "@utils/transform";
import { LeadInfoCardTableRow } from "@leads/cards/Base";
import { useLeadState } from "@leads/context";
import { rowCardColumnsFixedLong } from "@leads/defaults";
import { GridItem } from "@leads/Grid";
import { IncomeVerification } from "@api/graphql/types";

import { IncomeVerification as IncomeVerificationComponent } from "./";
import { VerifiedIncomeContent } from "./VerifiedIncomeContent";

import { get } from "lodash";

export const IncomeVerifications = () => {
	const {
		state: { lead, loading, error },
	} = useLeadState();

	const {
		incomeVerified,
		showLoanElements,
		showAccountElements,
		showRiskElements,
		showLoanBreakdown,
		averageMonthlySalary,
	} = useMemo(
		() => ({
			incomeVerified: !!lead?.incomeVerification?.accounts?.length,
			showLoanElements:
				!!lead?.incomeVerification?.attributes?.loanElements,
			showAccountElements:
				!!lead?.incomeVerification?.attributes?.riskElements,
			showRiskElements:
				!!lead?.incomeVerification?.attributes?.riskElements,
			showLoanBreakdown:
				!!lead?.incomeVerification?.attributes?.loanElements,
			averageMonthlySalary:
				lead?.incomeVerification?.attributes?.verifiedIncome || 0,
		}),
		[lead],
	);

	if (!incomeVerified) {
		return (
			<GridItem sm={12}>
				<IncomeVerificationRequest />
			</GridItem>
		);
	}

	// const IncomeVerificationCards = useMemo(() => incomeVerified ? makeCardGridItems<any, LeadDetails>([
	//     verifiedIncome,
	//     showRiskElements && accountElement,
	//     showLoanElements && loanBreakDown,
	//     // showRiskElements && riskElements,
	//     voidCheque,
	// ].filter(Boolean) as any) : makeCardGridItems<any, LeadDetails>([
	//     IncomeVerificationRequest,
	// ]), [
	//     incomeVerified,
	//     showLoanElements,
	//     showRiskElements,
	// ]);

	return (
		<Fragment>
			<GridItem>
				<VerifiedIncomeContent
					averageMonthlySalary={averageMonthlySalary}
				/>
			</GridItem>
			{showAccountElements && (
				<GridItem>
					<AccountElements />
				</GridItem>
			)}
			{showLoanBreakdown && (
				<GridItem>
					<LoanBreakdown />
				</GridItem>
			)}
			{/* {showRiskElements && <GridItem>
					<RiskElements />
				</GridItem>} */}
			<GridItem>
				<VoidCheque />
			</GridItem>
		</Fragment>
	);
};

export const LoanBreakdown = () => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();
	const loanElements = get(
		lead,
		"incomeVerification.attributes.loanElements",
	);

	const data = {
		title: "Monthly Loan Payments",
		minHeight: 370,
		tableOpts: {
			rowWidth: "auto",
		},
		selectors: [
			{
				property: "overallAmount",
				display: "Overall Loans:",
				format: transformToCurrency,
			},
			{
				property: "automotive",
				display: "Automotive:",
				format: transformToCurrency,
			},
			{
				property: "mortgages",
				display: "Mortgages:",
				format: transformToCurrency,
			},
			{
				property: "microLoans",
				display: "Micro Loans:",
				format: transformToCurrency,
			},
			{
				property: "studentLoans",
				display: "Student:",
				format: transformToCurrency,
			},
			{
				property: "otherLoans",
				display: "Other Loans:",
				format: transformToCurrency,
			},
		],
		data: loanElements,
		loading,
		error,
		print,
	};

	return (
		<Fragment>
			<LeadInfoCardTableRow {...data} />
		</Fragment>
	);
};

export const AccountElements = () => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();
	const riskElements = get(
		lead,
		"incomeVerification.attributes.riskElements",
	);

	const data = {
		title: "Account Element",
		minHeight: 370,
		selectors: [
			{
				property: "balanceMin",
				display: "Minimum Balance Detected:",
				format: transformToCurrency,
			},
			{
				property: "daysWithNegativeBalance90Days",
				display: "Days with negative balance (Last 90 days):",
			},
			{
				property: "balanceTrend",
				display: "Balance Trend:",
			},
		],
		data: riskElements,
		loading,
		error,
		print,
	};

	return (
		<Fragment>
			<LeadInfoCardTableRow {...data} />
		</Fragment>
	);
};

export const RiskElements = () => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();
	const riskElements = get(
		lead,
		"incomeVerification.attributes.riskElements",
	);

	const data = {
		title: "Risk Elements",
		minHeight: 370,
		selectors: [
			{
				property: "nsfFeesCounts",
				display: "NSF Fees Counts:",
			},
			{
				property: "stopPaymentFeesCount",
				display: "Stop Payment Fees Count (Last 90 days):",
			},
			{
				property: "microLoanPayments",
				display: "Micro-Loan Payments:",
			},
			{
				property: "expenditureAverageMonthly",
				display: "Expenditure (Average Monthly):",
			},
		],
		data: riskElements,
		loading,
		error,
		print,
	};

	return (
		<Fragment>
			<LeadInfoCardTableRow {...data} />
		</Fragment>
	);
};

export const VoidCheque = () => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();
	const { accounts, directDepositAccountId } = get(
		lead,
		"incomeVerification",
		{
			accounts: [],
			directDepositAccountId: "",
		},
	) as IncomeVerification;
	const account =
		accounts?.find((a) => a.id === directDepositAccountId) ||
		accounts?.find((a) => a.type === "Chequing");

	const data = {
		title: "Void Cheque",
		columns: rowCardColumnsFixedLong,
		selectors: [
			{
				property: "title",
				display: "Name:",
				copy: true,
			},
			{
				property: "transitNumber",
				display: "Transit Number",
				copy: true,
			},
			{
				property: "institutionNumber",
				display: "Institution Number:",
				copy: true,
			},
			{
				property: "accountNumber",
				display: "Account Number:",
				copy: true,
			},
			{
				property: "type",
				display: "Type:",
				copy: true,
			},
			{
				property: "currency",
				display: "Currency:",
				copy: true,
			},
		],

		data: account,
		loading,
		error,
		print,
	};

	return (
		<Fragment>
			<LeadInfoCardTableRow {...data} />
		</Fragment>
	);
};

export const IncomeVerificationRequest = () => {
	const {
		state: { lead, loading, error, print },
	} = useLeadState();

	const data = {
		title: "Income Verification",
		loading,
		error,
		consumer: lead!,
		print,
	};

	return (
		<Fragment>
			<IncomeVerificationComponent {...data} />
		</Fragment>
	);
};
