import React from "react";

import { Typography } from "~/components/Typography";

import { Box } from "@material-ui/core";

import { useStyles } from "./styles";

export interface IIncomeVerificationContentComponentProps {}

export const IncomeVerificationPending: React.FC<
	IIncomeVerificationContentComponentProps
> = () => {
	const styles = useStyles();
	return (
		<Box className={styles.content}>
			<Typography>
				An SMS has been sent to the lead to verify their Income
				documents.
			</Typography>
		</Box>
	);
};
