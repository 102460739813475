import React from "react";

import { Box, makeStyles } from "@material-ui/core";

import { InfoCard } from "~/components/InfoCard";

import ProductCta from ".";

import { ICTAComponentProps } from "./types";

const useStyles = makeStyles(() => {
	return {
		productCardWrap: {
			padding: "24px 0px",
			"@media print": {
				display: "none",
			},
		},
		productCardContent: {
			display: "flex",
			flexDirection: "column",
			flexWrap: "nowrap",
			justifyContent: "center",
			alignItems: "center",
			height: "100%",
		},
	};
});

export const ProductCtaCard = ({
	topic,
	variant,
	style,
}: ICTAComponentProps) => {
	const styles = useStyles();
	return (
		<div className={styles.productCardWrap} style={style || {}}>
			<InfoCard>
				<Box className={styles.productCardContent}>
					<ProductCta topic={topic} variant={variant} />
				</Box>
			</InfoCard>
		</div>
	);
};
