import React, { useEffect, useMemo, useState } from "react";

import { useAuth } from "@api/auth";
import { isProductEnabled } from "@utils/products";

import { Lead } from "@api/graphql/types";
import { useLeadActions } from "@api/leads";

import { InfoCard } from "~/components/InfoCard";
import Box from "@material-ui/core/Box";

import { Loading } from "./Loading";
import { IncomeVerificationPending } from "./Pending";
import { ProductType } from "@api/products";

import { PrimaryButton } from "~/components/Button/PrimaryButton";
import { Typography } from "~/components/Typography/Typography";
import { ProductCtaCard } from "~/components/ProductCta/Card";
import { useFeedbackContext } from "~/components/Feedback";

import { useStyles } from "./styles";

export interface IIncomeVerificationContentComponentProps {
	loading?: boolean;
	print?: boolean;
	consumer: Lead;
}

export const IncomeVerification = (
	props: IIncomeVerificationContentComponentProps,
) => {
	const styles = useStyles();
	const { selectedCompany } = useAuth();
	const isIncomeToolEnabled = isProductEnabled(
		selectedCompany,
		ProductType.IncomeTool,
	);
	if (!isIncomeToolEnabled) {
		return <ProductCtaCard topic="INCOME_TOOL" />;
	}

	return (
		<InfoCard
			title={"Income Verification"}
			classes={{ body: styles.container }}
		>
			{props?.loading ? (
				<Box
					display="flex"
					flexDirection="column"
					flexWrap="nowrap"
					justifyContent="center"
					alignItems="center"
					height="100%"
				>
					<Loading />
				</Box>
			) : (
				<IncomeVerificationContent {...(props as any)} />
			)}
		</InfoCard>
	);
};

export interface IIncomeVerificationNullComponentProps {
	requestIncomeVerification: () => void;
}

export const IncomeVerificationNull: React.FC<
	IIncomeVerificationNullComponentProps
> = ({ requestIncomeVerification }) => {
	const styles = useStyles();
	return (
		<Box className={styles.content}>
			<Typography className={styles.pendingTitle}>
				No income reports yet...
			</Typography>
			<Typography className={styles.pendingSubtitle}>
				Send a request to your Customer to verify their income and
				generate Void Cheque
			</Typography>
			<PrimaryButton onClick={requestIncomeVerification}>
				<Typography
					className={styles.verifyIncomeButton}
					variant="inherit"
				>
					Verify Income
				</Typography>
			</PrimaryButton>
		</Box>
	);
};

const IncomeVerificationContent: React.FC<
	IIncomeVerificationContentComponentProps
> = (props) => {
	const { consumer } = props;
	const { requestIncomeVerification } = useLeadActions(consumer?.id);
	const { handleOpenFeedback } = useFeedbackContext();
	const [loading, setLoading] = useState(false);

	const component = useMemo(() => {
		const request = async () => {
			try {
				setLoading(true);
				await requestIncomeVerification();
			} catch (error) {
				console.log("requestIncomeVerification", error);
				handleOpenFeedback({
					message: error.message,
					severity: "error",
				});
				setLoading(false);
			}
		};

		if (consumer?.incomeVerification?.accounts?.length) {
			return null;
		}

		const smsSent = consumer?.leadJourneys?.nodes?.find(
			(j) => j.stage === "INCOME_SMS_SENT",
		);
		const isCompleted = consumer?.leadJourneys?.nodes?.find(
			(j) => j.stage === "INCOME_COMPLETE",
		);
		if (loading || (smsSent && !isCompleted)) {
			return <IncomeVerificationPending />;
		}

		return <IncomeVerificationNull requestIncomeVerification={request} />;
	}, [
		consumer,
		loading,
		requestIncomeVerification,
		setLoading,
		handleOpenFeedback,
	]);

	return component;
};
