import Box from "@material-ui/core/Box";
import type { CardComponent } from "~/components/Cards/types";
import { InfoCard } from "~/components/InfoCard";
import React from "react";
import { Typography } from "~/components/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { transformToCurrency } from "@utils/transform";

export interface VerifiedIncomeContentProps {
	print?: boolean;
	loading?: boolean;
	averageMonthlySalary: number;
}

export const useStyles = makeStyles(({ typography }) => ({
	incomeQuantity: {
		...typography.title,
		lineHeight: "44px",
	},
	incomeDescription: {
		lineHeight: "44x",
		fontSize: "16px",
		fontWeight: "bold",
		margin: "6px 0px 12px 0px",
	},
}));

export const VerifiedIncomeContent = (props: VerifiedIncomeContentProps) => {
	const styles = useStyles();

	return (
		<InfoCard title="Verified Income">
			<Box
				display="flex"
				flexDirection="column"
				flexWrap="nowrap"
				justifyContent="center"
				alignItems="center"
				margin="10px 10px 25px"
				height="100%"
			>
				<Typography className={styles.incomeQuantity}>
					{transformToCurrency(props?.averageMonthlySalary || 0)}
				</Typography>
				<Typography className={styles.incomeDescription}>
					EMPLOYER INCOME
				</Typography>
				<Typography>(Average Monthly)</Typography>
			</Box>
		</InfoCard>
	);
};
