import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ typography, breakpoints }) => ({
	pendingTitle: {
		...typography.title,
		lineHeight: "44px",
	},
	pendingSubtitle: {
		margin: "4px 0 18px 0",
	},
	container: {
		padding: 32,
		[breakpoints.down(breakpoints.values.sm)]: {
			padding: 24,
		},
	},
	content: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		margin: "10px 10px 24px",
		height: "100%",
		textAlign: "center",
	},
	verifyIncomeButton: {
		[breakpoints.down(breakpoints.values.sm)]: {
			fontSize: 14,
		},
	},
}));
