import React from "react";
import Box from "@material-ui/core/Box";
import { Spinner } from "~/components/Spinner";

export const Loading: React.FC = () => {
	const square = 180;
	return (
		<Box>
			<Spinner
				style={{
					minHeight: square,
					maxHeight: square,
					width: 180,
				}}
			/>
		</Box>
	);
};
